import React, { MutableRefObject, RefObject, useRef, useState } from 'react'
import {
  FinalGrade,
  ManagerRecommendationInterface,
  ReviewDataInterface,
  ReviewedEmployeeInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Avatar,
  Badge,
  Box,
  Flex,
  Text,
  Token,
  useTooltip,
  Tooltip as UIKitTooltip,
  VStack,
  Widget,
  MoreBar,
  TooltipState,
} from '@revolut/ui-kit'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import { GradesMapInterface } from '@src/utils/grades'
import { RecommendationGradeRevamped } from '@src/pages/Forms/EmployeePerformance/components/RecommendationGradeRevamped'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { RecommendedGradesInterface } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { PerformanceEditSummary } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/PerformanceEditSummary'
import SideBar from '@components/SideBar/SideBar'
import { PreviousOverallFeedback } from '@src/pages/Forms/EmployeePerformanceLayout/components/PreviousOverallFeedback'
import { PerformanceHistorySection } from '@src/pages/EmployeeProfile/Layout/Performance/PerformanceHistorySection'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { PermissionTypes } from '@src/store/auth/types'

interface Props {
  isViewMode?: boolean
  recommendationData?: ManagerRecommendationInterface
  renderJustification: () => React.ReactNode
  renderError?: () => React.ReactNode | null
  onRadioChange?: (value: FinalGrade) => void
  onFeedbackClick?: () => void
  isSelfReview: boolean
  reviews?: ManagerRecommendationInterface[]
  feedbackContent: React.ReactNode
  recommendationRef?: RefObject<HTMLDivElement>
  reviewData?: ReviewDataInterface
  gradesMap: GradesMapInterface
  reviewedEmployee: ReviewedEmployeeInterface
}

const GradeRatingWithTooltip = ({
  option,
  isViewMode,
  items,
  gradesMap,
  recommendedGrades,
  optionTooltip,
}: {
  gradesMap: GradesMapInterface
  option: { key: FinalGrade; items: string[] }
  isViewMode: boolean
  items?: ManagerRecommendationInterface[]
  recommendedGrades: RecommendedGradesInterface
  optionTooltip: TooltipState<Element>
}) => {
  const ratingTooltip = useTooltip()

  return (
    <Flex gap="s-8" {...ratingTooltip.getAnchorProps()} width="fit-content">
      <RecommendationGradeRevamped
        gradesMap={gradesMap}
        recommendedGrades={recommendedGrades}
        value={option.key}
        fontWeight={500}
        variant="primary"
        pb="s-8"
        tooltip={optionTooltip}
      />
      {isViewMode && !!items?.length && (
        <>
          <Badge
            minWidth="s-20"
            height="s-16"
            mt="s-2"
            backgroundColor={Token.color.deepGrey}
            style={{ fontWeight: 500 }}
          >
            {items.length}
            <UIKitTooltip
              {...ratingTooltip.getTargetProps()}
              maxWidth={200}
              placement="right-start"
            >
              <VStack>
                <Text variant="small" fontWeight="bold">
                  {gradesMap[option.key]}
                </Text>
                {items.map(item => (
                  <Text key={item.reviewer?.id} variant="small">
                    {item.reviewer?.full_name}
                  </Text>
                ))}
              </VStack>
            </UIKitTooltip>
          </Badge>
        </>
      )}
    </Flex>
  )
}

const PerformanceOption = ({
  option,
  optionIdx,
  recommendedGrades,
  tooltipAnchor,
  reviews,
  recommendationData,
  isViewMode = false,
  onRadioChange,
  gradesMap,
}: {
  option: { key: FinalGrade; items: string[] }
  optionIdx: number
  recommendedGrades: RecommendedGradesInterface
  tooltipAnchor: MutableRefObject<HTMLDivElement[]>
  reviews?: ManagerRecommendationInterface[]
  recommendationData: ManagerRecommendationInterface
  isViewMode?: boolean
  onRadioChange?: (value: FinalGrade) => void
  gradesMap: GradesMapInterface
}) => {
  const optionTooltip = useTooltip()

  const items = reviews?.filter(
    item => item.employee_project_performance.value === option.key,
  )
  const checked = isViewMode
    ? !!items?.length
    : recommendationData.employee_project_performance.value === option.key

  return (
    <Box
      key={optionIdx}
      px="s-16"
      mb="s-16"
      data-testid={`feedback-${option.key}`}
      borderRadius={Token.radius.r12}
      bg={!isViewMode && checked ? Token.color.blue_5 : undefined}
    >
      <Flex>
        <RadioButton
          label={
            <div>
              <Box p="s-12" {...optionTooltip.getAnchorProps()}>
                <GradeRatingWithTooltip
                  option={option}
                  isViewMode={isViewMode}
                  items={items}
                  gradesMap={gradesMap}
                  recommendedGrades={recommendedGrades}
                  optionTooltip={optionTooltip}
                />
                <ul
                  style={{
                    paddingInlineStart: 16,
                    margin: 0,
                    color: Token.color.greyTone50,
                  }}
                >
                  {option.items.map((item, i) => (
                    <li key={i}>
                      <Text variant="caption" color={Token.color.greyTone50}>
                        {item}
                      </Text>
                    </li>
                  ))}
                </ul>
              </Box>
            </div>
          }
          checked={checked}
          disabled={isViewMode}
          onChange={() => onRadioChange?.(option.key)}
          inputRef={el => {
            tooltipAnchor.current[optionIdx] = el
          }}
          alignTop
          capitalize={false}
        />
      </Flex>
    </Box>
  )
}

export const FeedbackCommon = connect(
  ({
    isViewMode = false,
    recommendationData,
    reviews,
    renderError,
    renderJustification,
    onRadioChange,
    isSelfReview,
    feedbackContent,
    recommendationRef,
    reviewData,
    gradesMap,
    reviewedEmployee,
  }: Props) => {
    const [summarySidebarOpen, setSummarySidebarOpen] = useState(false)
    const [scrollToNotes, setScrollToNotes] = useState(false)
    const [historySidebarOpen, setHistorySidebarOpen] = useState(false)
    const tooltipAnchor = useRef<HTMLDivElement[]>([])
    const performanceOptions = recommendationData?.employee_project_performance.options
    const recommendedGrades = useRecommendedGradesContext()

    const { data: employee } = useGetEmployeeWithManagerFilter(
      reviewedEmployee.id,
      'line_manager__id',
    )
    const canViewHistory =
      employee &&
      employee.field_options?.permissions?.includes(
        PermissionTypes.ViewPerformanceHistoryTab,
      )

    return (
      <Widget>
        <Flex alignItems="center" justifyContent="space-between" p="s-16">
          <Flex alignItems="center" gap="s-16" width="30%">
            <Avatar useIcon="40/SubtractStar" />
            <Text variant="primary" fontWeight={400}>
              Feedback
            </Text>
          </Flex>
        </Flex>
        {!isViewMode && (
          <Box ml="s-16" mb="s-12">
            <MoreBar>
              <MoreBar.Action
                useIcon="BalanceSheet"
                onClick={() => setSummarySidebarOpen(!summarySidebarOpen)}
              >
                View summary details
              </MoreBar.Action>
              {canViewHistory && (
                <MoreBar.Action
                  useIcon="Time"
                  onClick={() => setHistorySidebarOpen(!historySidebarOpen)}
                >
                  Performance history
                </MoreBar.Action>
              )}
              <MoreBar.Action
                useIcon="Chat"
                onClick={() => {
                  setScrollToNotes(true)
                  setSummarySidebarOpen(!summarySidebarOpen)
                }}
              >
                View 1:1 notes
              </MoreBar.Action>
            </MoreBar>
            <SideBar
              variant="wide"
              isOpen={summarySidebarOpen}
              onClose={() => {
                setScrollToNotes(false)
                setSummarySidebarOpen(false)
              }}
            >
              <PerformanceEditSummary
                reviewData={reviewData}
                scrollToNotes={scrollToNotes}
              />
            </SideBar>
            {canViewHistory && (
              <SideBar
                variant="wide"
                isOpen={historySidebarOpen}
                onClose={() => setHistorySidebarOpen(false)}
              >
                <PerformanceHistorySection
                  data={employee}
                  canViewProgression
                  onRowClick={() => {}}
                />
              </SideBar>
            )}
          </Box>
        )}
        <Box p="s-16">
          <VStack space="s-16">
            {recommendationData && (
              <>
                <Text variant="primary" fontWeight={600} ref={recommendationRef}>
                  {recommendationData.employee_project_performance.name}
                </Text>
                {performanceOptions?.map((option, optionIdx) => (
                  <PerformanceOption
                    key={option.key}
                    option={option}
                    optionIdx={optionIdx}
                    recommendedGrades={recommendedGrades}
                    tooltipAnchor={tooltipAnchor}
                    gradesMap={gradesMap}
                    onRadioChange={onRadioChange}
                    reviews={reviews}
                    recommendationData={recommendationData}
                  />
                ))}
                {renderError?.()}
                {renderJustification()}
              </>
            )}
            <VStack mt="s-8">
              {!isSelfReview && (
                <Text variant="primary" fontWeight={600} mb={isViewMode ? '-s-8' : 's-8'}>
                  What feedback do you want to provide?
                </Text>
              )}
              {feedbackContent}
            </VStack>
            {!isViewMode && (
              <PreviousOverallFeedback data={reviewData?.previous_reviews_by_cycle} />
            )}
          </VStack>
        </Box>
      </Widget>
    )
  },
)
