import React, { useState } from 'react'
import { HStack, Icon, IconButton, Spinner, Text, Token } from '@revolut/ui-kit'
import { Download } from '@revolut/icons'
import { capitalize } from 'lodash'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import {
  AccountsSettingsInterface,
  LatestSubscriptionInvoiceStatus,
} from '@src/interfaces/settings'
import { selectorKeys } from '@src/constants/api'
import {
  TenantOnboardingProgress,
  formatAdminName,
  getAccountStatusColor,
  getAccountStatusTitle,
} from '@src/pages/Settings/Accounts/common'
import { ApprovalFlowSettingStep } from '@src/interfaces/settings'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { SubscriptionInvoiceInterface } from '@src/interfaces/plans'
import { downloadTenantInvoice } from '@src/api/tenants'
import { saveFile } from '@src/utils'
import {
  OnboardingCheckpointSelectorInterface,
  TenantOnboardingCheckpointInterface,
} from '@src/interfaces/onboardingChecklist'

export const accountCompanyNameColumn: ColumnInterface<AccountsSettingsInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'company_name',
  sortKey: 'company_name',
  filterKey: 'id',
  selectorsKey: selectorKeys.tenant_companies,
  title: 'Company',
}

export const accountCountryColumn: ColumnInterface<AccountsSettingsInterface> = {
  type: CellTypes.text,
  idPoint: 'country.id',
  dataPoint: 'country.name',
  sortKey: 'country',
  filterKey: 'country',
  selectorsKey: selectorKeys.tenant_countries,
  title: 'Country',
}

export const accountHeadcountColumn: ColumnInterface<AccountsSettingsInterface> = {
  type: CellTypes.text,
  idPoint: 'active_headcount',
  dataPoint: 'active_headcount',
  sortKey: 'active_headcount',
  filterKey: 'active_headcount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Active employees',
}

export const accountPaymentDetailsValidColumn: ColumnInterface<AccountsSettingsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'is_payment_method_set',
    dataPoint: 'is_payment_method_set',
    sortKey: 'is_payment_method_set',
    filterKey: 'is_payment_method_set',
    selectorsKey: selectorKeys.yes_no_value_options,
    title: 'Payment details valid',
    insert: ({ data }) =>
      data.is_payment_method_set == null
        ? '-'
        : data.is_payment_method_set
        ? 'Yes'
        : 'No',
  }

export const getLastInvoiceStatusColor = (status?: LatestSubscriptionInvoiceStatus) => {
  const type = typeof status === 'string' ? status : status?.id

  switch (type) {
    case 'open':
      return Token.color.orange
    case 'paid':
      return Token.color.green
    default:
      return ''
  }
}

export const accountLastInvoiceStatusColumn: ColumnInterface<AccountsSettingsInterface> =
  {
    colors: data => getLastInvoiceStatusColor(data?.latest_subscription_invoice_status),
    type: CellTypes.insert,
    idPoint: 'latest_subscription_invoice_status',
    dataPoint: 'latest_subscription_invoice_status',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Last invoice status',
    insert: ({ data }) =>
      typeof data.latest_subscription_invoice_status === 'string'
        ? capitalize(data.latest_subscription_invoice_status)
        : data.latest_subscription_invoice_status?.name || '-',
  }

export const accountSubdomainColumn: ColumnInterface<AccountsSettingsInterface> = {
  type: CellTypes.insert,
  idPoint: 'subdomain',
  dataPoint: 'subdomain',
  sortKey: 'subdomain',
  filterKey: 'id',
  selectorsKey: selectorKeys.tenant_subdomains,
  title: 'Subdomain',
  insert: ({ data }) => <>{data.subdomain || '-'}</>,
}

export const accountAdminColumn: ColumnInterface<AccountsSettingsInterface> = {
  type: CellTypes.insert,
  idPoint: 'admin',
  dataPoint: '',
  sortKey: 'admin_full_name',
  filterKey: 'admin',
  selectorsKey: selectorKeys.tenant_admins,
  title: 'Admin',
  insert: ({ data }) => formatAdminName(data),
}

export const accountProgressColumn = (
  onSelectProgress: (progress?: TenantOnboardingCheckpointInterface[]) => void,
  checkpointsSelector?: OnboardingCheckpointSelectorInterface[],
): ColumnInterface<AccountsSettingsInterface> => ({
  type: CellTypes.insert,
  idPoint: 'onboarding_stats',
  dataPoint: 'onboarding_stats',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Onboarding progress',
  insert: ({ data }) => (
    <TenantOnboardingProgress
      onSelectProgress={onSelectProgress}
      checkpointsSelector={checkpointsSelector}
      progress={data.onboarding_stats}
    />
  ),
})

export const accountSubscriptionStateColumn: ColumnInterface<AccountsSettingsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'subscription_state',
    dataPoint: 'subscription_state',
    sortKey: 'subscription_state',
    filterKey: 'subscription_state',
    selectorsKey: selectorKeys.tenant_subscription_states,
    title: 'Subscription status',
    insert: ({ data }) => {
      if (
        (data.subscription_plan === null ||
          data.subscription_plan?.manual_payment === null) &&
        !!data.custom_plan_requested_at
      ) {
        return (
          <HStack space="s-4" color={Token.color.warning} align="center">
            <Text>Custom plan request</Text>
            <Icon name="ExclamationMark" size={14} />
          </HStack>
        )
      }

      return data.subscription_state?.name || '-'
    },
  }

export const accountStatusColumn: ColumnInterface<AccountsSettingsInterface> = {
  type: CellTypes.insert,
  idPoint: 'state',
  dataPoint: 'state',
  sortKey: 'state',
  filterKey: 'state',
  selectorsKey: selectorKeys.tenant_statuses,
  colors: data => getAccountStatusColor(data.state),
  title: 'Status',
  insert: ({ data }) => getAccountStatusTitle(data.state),
}

export const approvalStepsNameColumn: ColumnInterface<ApprovalFlowSettingStep> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Approval step',
}

export const approvalStepsApproverTypeColumn: ColumnInterface<ApprovalFlowSettingStep> = {
  type: CellTypes.text,
  idPoint: 'approver_type.id',
  dataPoint: 'approver_type.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Approval type',
}

export const approvalStepsApproversColumn: ColumnInterface<ApprovalFlowSettingStep> = {
  type: CellTypes.insert,
  idPoint: 'approvers',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Approvers',
  dynamicHyperlinks: ({ approver_type, group_approver }) => {
    if (approver_type.id === 'group' && group_approver) {
      return pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, { id: group_approver.id })
    }
    return null
  },
  insert: ({ data }) => {
    if (data.approver_type.id === 'relationship' && data.relationship) {
      return <Text>{data.relationship.name}</Text>
    }
    if (data.approver_type.id === 'group' && data.group_approver) {
      return <Text>{data.group_approver.name}</Text>
    }
    if (data.approver_type.id === 'employee' && data.employee_approver) {
      return <UserWithAvatar {...data.employee_approver} />
    }
    return null
  },
}

interface DownloadInvoiceActionProps {
  id: string
  invoiceId: string | number
}

const DownloadInvoiceAction = ({ id, invoiceId }: DownloadInvoiceActionProps) => {
  const [pending, setPending] = useState(false)

  return pending ? (
    <Spinner color={Token.color.blue} size={16} />
  ) : (
    <IconButton
      color={Token.color.blue}
      size={16}
      aria-label="Download invoice"
      useIcon={Download}
      onClick={e => {
        e.stopPropagation()
        setPending(true)

        downloadTenantInvoice(id, invoiceId)
          .then(response => {
            saveFile(response.file, response.fileName)
          })
          .finally(() => {
            setPending(false)
          })
      }}
    />
  )
}

export const accountInvoiceActionColumn = (
  id: string,
): ColumnInterface<SubscriptionInvoiceInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) =>
    data.manual_payment ? '-' : <DownloadInvoiceAction id={id} invoiceId={data.id} />,
})
