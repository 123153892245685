import { EntityTypes } from '@src/constants/api'
import { FieldOptions, IdAndName, Statuses } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Month } from '@src/api/calendar'
import { VisualisationVariant } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/VisualisationSidebar'
import { VisualisationType } from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/common'

export enum QueryRunStatuses {
  Error = 'error',
  Running = 'running',
  Success = 'success',
  Queued = 'queued',
}

export enum QueryParameterTypes {
  Date = 'date',
  Number = 'number',
  Text = 'text',
}

export type QueryStatuses = 'active' | 'archived' | 'draft' | 'outdated'

export type ParameterDefaultValue = Date | string | null

export type ReportDownloadOutputFormat = 'csv' | 'xlsx'

export type ReportTargetTypes = 'download' | 'ui'

export interface QueryParameterInterface {
  default: ParameterDefaultValue
  name: string
  type: QueryParameterTypes
}

export interface QueryColumnInterface {
  name: string
  type: string
  description: string | null
}

export interface ReportColumnsTableInterface {
  name: string
  alias: string | null
  type: string | null
}

export interface ReportParametersTableInterface {
  name: string
  value: ParameterDefaultValue
}

export interface TenantDeploymentInterface {
  all_tenants: boolean
  tenants: IdAndName<string>[]
  id: number
  query_id: number
}

export type QueryViewType = 'chart' | 'table'

export interface ChartSettings {
  xValue?: IdAndName
  yValue?: IdAndName[]
  chartType?: VisualisationType
  chartVariant?: IdAndName<VisualisationVariant>
  title?: string
  subtitle?: string
}

export interface QueryInterface {
  id: number
  name: string
  description: string
  creation_date_time: string
  update_date_time: string
  latest_run_date_time: string | null
  status: QueryStatuses
  sql: string
  connection: ConnectionInterface
  owner: EmployeeOptionInterface
  parameters: QueryParameterInterface[]
  columns: QueryColumnInterface[]
  field_options?: FieldOptions
  is_publishable: boolean
  is_system_owned: boolean
  tenant_deployment?: TenantDeploymentInterface | null
  visualisation: ChartSettings
  visualisation_type: QueryViewType
  output_format?: ReportOutputFormat
}

export interface ConnectionInterface {
  id: number
  name: string
  description: string
  type: string
  status: Statuses.active | Statuses.archived
  owner: EmployeeOptionInterface
  creation_date_time: string
  update_date_time: string
  database?: string
  host: string
  port: number
  username: string
  password?: string
  extra?: any
  field_options?: FieldOptions
}

export interface ConnectionTestInterface {
  success: boolean
  detail: string
}

export interface RunQueryRequestInterface {
  query: {
    connection: {
      id: number
    }
    owner: {
      id: number
    } | null
    sql: string
  }
  parameters: { [key: string]: ParameterDefaultValue }
}

export interface RunQueryResponseInterface {
  id: number
  creation_date_time: string
  update_date_time: string
  start_date_time: string
  end_date_time: string
  execution_duration: string
  status: QueryRunStatuses
  detail: string | null
  parameters: { [key: string]: ParameterDefaultValue }
  query: {
    id: number
  }
  triggered_by: {
    id: number
  }
  owner: EmployeeOptionInterface
  result?: {
    row_count: number
    expired: boolean
  }
}

export type QueryRunResultColumnsInterface = { name: string; type: string }[]

export type QueryRunResultItemInterface = { [key: string]: any }

export type QueryRunResultInterface = QueryRunResultItemInterface[]

export interface ReportFilterInterface {
  column_name: string
  default_value: string | null
}

export const ReportHighlightColors = ['green', 'yellow', 'orange', 'red'] as const
export type ReportHighlightColor = typeof ReportHighlightColors[number]

export interface ReportHighlightInterface {
  condition: { key: string; value: string }
  target: IdAndName<'row' | 'value'>
  color: ReportHighlightColor
}

export interface ReportOutputFormat {
  format?: ReportDownloadOutputFormat
  columns: ReportColumnsTableInterface[]
  ordering?: string[]
  filters?: ReportFilterInterface[]
  summary_column?: string | null
  context_filters?: {
    column_name: string | null
    context_type: IdAndName<EntityTypes> | null
  }[]
  highlighting?: ReportHighlightInterface[] | null
}

export interface ReportInterface {
  id: number
  creation_date_time: string
  update_date_time: string
  latest_run_date_time: string
  next_run_date_time: string
  name: string
  description: string
  query: QueryInterface
  owner: EmployeeOptionInterface
  status: Statuses.active | Statuses.archived
  default_query_parameters: { [key: string]: ParameterDefaultValue }
  report_type: ReportTargetTypes
  output_format: ReportOutputFormat
  run_every?: IdAndName<'day' | 'week' | 'month' | 'year'> | null
  run_on_day_of_month?: IdAndName<string> | null
  run_on_day_of_week?: IdAndName<string> | null
  run_on_month?: IdAndName<Month> | null
  run_on_time?: string | null
  run_on_timezone?: {
    id: string
    name: string
    offset: string
  } | null
  schedule?: string | null
  scheduling_type?: IdAndName<'advanced' | 'basic'> | null
}

export interface RunReportResponseInterface {
  id: number
  creation_date_time: string
  update_date_time: string
  execution_duration: string
  status: QueryRunStatuses
  output_uri: string | null
  error_detail: string | null
  execution_parameters: { [key: string]: ParameterDefaultValue }
  report: {
    id: number
    name: string
    output_format: ReportDownloadOutputFormat
  }
  triggered_by: EmployeeOptionInterface
  query_run: {
    id: number
    detail: string | null
  }
}
