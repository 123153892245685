import { useGetPerformanceSelector } from '@src/api/performance'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import CycleSelector, { CycleSelectorProps } from './CycleSelector'
import { getGenericCyclesInfo } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import { ReviewCycleStatus, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import React, { useState } from 'react'
import { PerformanceSelector } from '@src/interfaces/performance'

export const GoalTargetCycleSelector = ({
  onSelect,
  isEmployee,
  ownerId,
  initialCycle,
  ...selectorPropsOverride
}: {
  onSelect?: (cycle: ReviewCyclesInterface) => void
  isEmployee: boolean
  ownerId?: number
  initialCycle?: ReviewCyclesInterface
} & Partial<CycleSelectorProps>) => {
  const { data: employeeAvailableCycles } = useGetPerformanceSelector(ownerId)
  const { data: reviewCyclesData } = useGetReviewCycles()
  const [targetCycle, setTargetCycle] = useState<
    ReviewCyclesInterface | PerformanceSelector | undefined
  >(initialCycle)

  const getAvailableCyclesInfo = (targetIndex: number | null) => {
    const reviewCycles = reviewCyclesData?.results || []
    return getGenericCyclesInfo(reviewCycles, {
      targetIndex,
      allTargets: [],
    })
  }

  const { availableCycles } = getAvailableCyclesInfo(null)
  const employeeFilteredCycles = employeeAvailableCycles?.filter(
    cycle => cycle.status !== ReviewCycleStatus.closed,
  )
  const cycles = isEmployee ? employeeFilteredCycles || availableCycles : availableCycles

  const onChange = (cycle: ReviewCyclesInterface) => {
    onSelect && onSelect(cycle)
    setTargetCycle(cycle)
  }

  // THIS IS AWEFUL BUT FIXES THE ISSUE WITH DISPLAYING DATA IN SELECTOR
  // So many "as" relating to this part of the code that it's ultra hard to debug what's where
  // and trying to untangle that seems to be ultra timeconsuming added this to the refactor ticket
  // https://revolut.atlassian.net/browse/REVC-6258
  const value =
    targetCycle ||
    (cycles as ReviewCyclesInterface[])?.find(cycle => cycle?.offset === 0) ||
    cycles?.[0]

  return (
    <>
      <CycleSelector
        value={value as ReviewCyclesInterface}
        reviewCycles={cycles}
        onSelect={onChange}
        {...selectorPropsOverride}
      />
    </>
  )
}
