import React, { useEffect } from 'react'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { getLocationDescriptor, useOpenNewTab } from '@src/actions/RouterActions'
import { OptionInterface } from '@src/interfaces/selectors'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { getSelectors } from '@src/api/selectors'

type LapeScorecardFieldProps = {
  canAddNewScorecard?: boolean
  isClearable: boolean
  isDisabled?: boolean
  isOnboarding?: boolean
  isRequired?: boolean
}

const LapeScorecardField = ({
  canAddNewScorecard = false,
  isClearable,
  isDisabled,
  isOnboarding = false,
  isRequired,
}: LapeScorecardFieldProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const openNewTab = useOpenNewTab()

  const onFocus = async () => {
    // we create a scorecard in a new tab, close it there, user gets back to this page, we listen a focus event and prefill with the newly created scorecard
    const createdScorecardId = workspaceLocalStorage.getItem(
      LOCAL_STORAGE.HIRING_STAGE_CREATED_SCORECARD,
    )

    if (createdScorecardId) {
      const templatesResp = await getSelectors(selectorKeys.interview_scorecard_templates)
      const templates = templatesResp.data.options

      values.scorecard_template = templates?.find(item => item.id === +createdScorecardId)
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.HIRING_STAGE_CREATED_SCORECARD)
    }
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  return (
    <LapeRadioSelectInput<OptionInterface>
      disabled={isDisabled}
      name="scorecard_template"
      required={isRequired}
      label="Scorecard"
      clearable={isClearable}
      selector={selectorKeys.interview_scorecard_templates}
      useQuery
      useQueryOptions={{
        refetchOnWindowFocus: true,
      }}
      showCreateNewButton={canAddNewScorecard}
      onChange={option => {
        if (option) {
          if (option?.id === createNewKey) {
            openNewTab(
              pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS, undefined, {
                ref: isOnboarding ? 'onboarding' : 'fill-scorecard-after-submit',
              }),
            )
          } else {
            values.scorecard_template = option
          }
        }
      }}
      referenceUrl={
        values.scorecard_template?.id
          ? getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
                id: values.scorecard_template.id,
              }),
            )
          : undefined
      }
    />
  )
}

export default LapeScorecardField
