import React, { useEffect } from 'react'
import PageLoading from '@components/PageLoading/PageLoading'
import { GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM } from '@src/constants/api'
import {
  connectGmail,
  connectGoogleCalendar,
  GoogleConsentSourceType,
  GoogleConsentType,
} from '@src/api/google'
import * as Sentry from '@sentry/react'
import { LocalStorageKeys } from '@src/store/auth/types'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

const GrantPermissionsRedirect = () => {
  useEffect(() => {
    const validateToken = async () => {
      const currentURL = new URL(window.location.href)
      const token = currentURL.searchParams.get('code')
      const state = currentURL.searchParams.get('state')
      let source: GoogleConsentSourceType | undefined
      let type: GoogleConsentType | undefined

      try {
        const parsedState: unknown = state && JSON.parse(state)

        if (typeof parsedState === 'object' && parsedState !== null) {
          if ('source' in parsedState) {
            source = parsedState.source as GoogleConsentSourceType | undefined
          }

          if ('type' in parsedState) {
            type = parsedState.type as GoogleConsentType | undefined
          }
        }
      } catch (e) {
        Sentry.captureException(e)
        source = undefined
        type = undefined
      }

      try {
        if (token) {
          if (type === 'email') {
            await connectGmail(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM, source)
          } else if (type === 'calendar') {
            await connectGoogleCalendar(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
          } else {
            Sentry.captureMessage(
              '[GrantPermissionsRedirect] type is required but not defined',
            )
          }
        }
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            type,
          },
        })

        Sentry.captureMessage(`[GrantPermissionsRedirect] ${token} ${state}`)

        // for some unknown reason occasionally we land to this page twice with the same oauth code and get 403, added some logs and skip it for now
        if (e?.response?.status !== 403) {
          // save the error in the localstorage as we close the window
          workspaceLocalStorage.setItem(
            LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR,
            e?.response?.data?.[0] || UNKNOWN_ERROR,
          )
        }
      } finally {
        if (window && window.opener && window.opener.location) {
          window.opener.location.reload()
          window.close()
        }
      }
    }

    validateToken()
  }, [])

  return <PageLoading />
}

export default GrantPermissionsRedirect
