import React from 'react'
import { InternalDashboardItemInterface } from '@src/interfaces/analyticsDashboards'
import { MetricsItem } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsItem'
import { Grid, AspectRatio, Token, useMatchMedia } from '@revolut/ui-kit'

interface MetricsGridProps {
  layout: InternalDashboardItemInterface[]
}

const getGridArea = (item: InternalDashboardItemInterface) => {
  const rowStart = item.row + 1
  const colStart = item.column + 1
  const rowEnd = rowStart + item.height
  const colEnd = colStart + item.width

  return `${rowStart} / ${colStart} / ${rowEnd} / ${colEnd}`
}

export const MetricsGrid = ({ layout }: MetricsGridProps) => {
  const isMediumScreen = useMatchMedia(Token.media.md)

  return (
    <>
      <Grid columns={{ all: 1, md: 6 }} gap="s-16">
        {layout.map(item => (
          <AspectRatio
            ratio={isMediumScreen ? item.width / item.height : 1}
            key={item.query.id}
            style={
              isMediumScreen
                ? {
                    gridArea: getGridArea(item),
                  }
                : undefined
            }
            bg={Token.color.widgetBackground}
            radius="widget"
            width="100%"
            height="100%"
            p="s-16"
            overflow="scroll"
          >
            <MetricsItem queryId={item.query.id} />
          </AspectRatio>
        ))}
      </Grid>
    </>
  )
}
