import React from 'react'
import { FinalGrade, getGradeColor } from '@src/interfaces/performance'
import {
  TextProps,
  Text,
  Flex,
  Badge,
  Token,
  Tooltip,
  VStack,
  TooltipState,
} from '@revolut/ui-kit'
import { GradesMapInterface } from '@src/utils/grades'
import { RecommendedGradesInterface } from '@src/pages/Forms/EmployeePerformanceLayout/utils'

interface Props extends TextProps {
  value: FinalGrade
  gradesMap: GradesMapInterface
  recommendedGrades: RecommendedGradesInterface
  tooltip: TooltipState<Element>
}

export const RecommendationGradeRevamped = ({
  value,
  gradesMap,
  recommendedGrades,
  tooltip,
  ...rest
}: Props) => {
  const goalsGrade =
    recommendedGrades.grades?.kpiGrade || recommendedGrades.grades?.deliverablesGrade
  const skillsGrade = recommendedGrades.grades?.skillsGrade
  const valuesGrade =
    recommendedGrades.grades?.cultureValuesGrade ||
    recommendedGrades.grades?.cultureSkillsGrade

  const hasSkillsAndValues = skillsGrade && valuesGrade
  const hasSkillsOrValues = skillsGrade || valuesGrade
  const goalsPercentage =
    goalsGrade && hasSkillsAndValues
      ? '50%'
      : goalsGrade && hasSkillsOrValues
      ? '66.6%'
      : ''
  const skillsValuesPercentage = hasSkillsAndValues ? '25%' : '33.3%'

  return (
    <Flex gap="s-8">
      <Text use="div" {...rest}>
        {gradesMap[value]}
      </Text>
      {value === recommendedGrades.recommendedGrade && (
        <>
          <Badge size={20} bg={Token.color.blue_10} color={Token.color.blue} px="s-8">
            Recommended
          </Badge>
          <Tooltip {...tooltip.getTargetProps()} placement="right-end" width={300}>
            <VStack p="s-12">
              <Text variant="primary" mb="s-8">
                Why is this grade recommended?
              </Text>
              {goalsGrade && (
                <Flex justifyContent="space-between">
                  <Text color={Token.color.greyTone50}>{goalsPercentage} Goals</Text>
                  <Text color={getGradeColor(goalsGrade)}>{gradesMap[goalsGrade]}</Text>
                </Flex>
              )}
              {skillsGrade && (
                <Flex justifyContent="space-between">
                  <Text color={Token.color.greyTone50}>
                    {skillsValuesPercentage} Skills
                  </Text>
                  <Text color={getGradeColor(skillsGrade)}>{gradesMap[skillsGrade]}</Text>
                </Flex>
              )}
              {valuesGrade && (
                <Flex justifyContent="space-between">
                  <Text color={Token.color.greyTone50}>
                    {skillsValuesPercentage} Values
                  </Text>
                  <Text color={getGradeColor(valuesGrade)}>{gradesMap[valuesGrade]}</Text>
                </Flex>
              )}
            </VStack>
          </Tooltip>
        </>
      )}
    </Flex>
  )
}
