import React, { useState } from 'react'
import {
  Group,
  Header,
  ItemSkeleton,
  Layout,
  VStack,
  Text,
  PlanWidgetSkeleton,
  Subheader,
  Item,
  TabBar,
  Footnote,
  Button,
  Token,
  TextSkeleton,
  Link,
  HStack,
  Icon,
  Widget,
  Separator,
  Tag,
  Image,
  useStatusPopup,
  StatusPopup,
  useToast,
  Toast,
  TabBarSkeleton,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'
import { subDays } from 'date-fns'

import { formatDate, formatMoney } from '@src/utils/format'
import { features } from './config'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { ROUTES, PUBLIC_ROUTES } from '@src/constants/routes'
import { useSubscriptionPlanContext } from './SubscriptionPlanProvider'
import {
  requestManualPlan,
  changeMerchantApiPaymentMethod,
  createMerchantApiOrder,
  getMerchantApiConfig,
  useAvailableSubscriptionPlans,
  useDiscounts,
  useSubsciptionInfo,
} from '@src/api/plans'
import { pathToUrl } from '@src/utils/router'
import { TermsAndConditionsDisclaimer } from '@src/pages/SignUp/common'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useScrollToTop } from '@src/hooks/useScrollToTop'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const SelectPlan = () => {
  const { plans } = useAvailableSubscriptionPlans()
  const { selectedPlan, setSelectedPlan, setOrderAndConfig } =
    useSubscriptionPlanContext()
  const { info, isLoading: isInfoLoading } = useSubsciptionInfo()
  const { discounts, isLoading: isDiscountsLoading } = useDiscounts()
  const featureFlags = useSelector(selectFeatureFlags)

  const statusPopup = useStatusPopup()
  const toast = useToast()

  useScrollToTop()

  const [createOrderPending, setCreateOrderPending] = useState(false)
  const [applyForAnnualPlanPending, setApplyForAnnualPlanPending] = useState(false)
  const [planType, setPlanType] = useState<'monthly' | 'annual'>()
  const [hasAppliedForAnnualPlan, setHasAppliedForAnnualPlan] = useState(false)

  const noPaymentMethod = info?.revolut_merchant_api_payment_method === null
  const isOnCustomPlan = !!info?.subscription_plan?.manual_payment
  const isOnMonthlyPlan = info?.subscription_plan?.manual_payment === null
  const subscribedPlan = info?.subscription_plan
  const subscribedPlanId = info?.subscription_plan?.id
  const isCurrentPlanSelected =
    selectedPlan && subscribedPlanId === selectedPlan.subscription_plan.id
  const appliedDiscount = discounts?.find(
    d =>
      d.subscription_plan.id === selectedPlan?.subscription_plan.id &&
      d.status.id === 'active',
  )
  const planPrice = selectedPlan
    ? formatMoney(
        Number(selectedPlan.subscription_plan.monthly_fee),
        selectedPlan.subscription_plan.currency,
      )
    : null
  const discountPrice =
    selectedPlan && appliedDiscount
      ? formatMoney(
          Number(appliedDiscount.monthly_fee),
          selectedPlan.subscription_plan.currency,
        )
      : null
  const finalPrice = discountPrice || planPrice
  const isCustomPlansFeatureEnabled = featureFlags.includes(
    FeatureFlags.SubscriptionPlansCustomPlans,
  )

  /** We only plan to support one plan for a while */
  if (!selectedPlan && plans?.[0]) {
    setSelectedPlan(plans[0])
  }

  if (!!info?.custom_plan_requested_at && !hasAppliedForAnnualPlan) {
    setHasAppliedForAnnualPlan(true)
  }

  if (!planType && (isOnCustomPlan || hasAppliedForAnnualPlan)) {
    setPlanType('annual')
  }

  if (
    !planType &&
    (isOnMonthlyPlan || isCurrentPlanSelected || (!isInfoLoading && !subscribedPlanId))
  ) {
    setPlanType('monthly')
  }

  const onPlanSelect = () => {
    if (selectedPlan && !createOrderPending) {
      setCreateOrderPending(true)

      Promise.all([
        getMerchantApiConfig(),
        createMerchantApiOrder(selectedPlan.subscription_plan.id),
      ])
        .then(([configResponse, orderResponse]) => {
          setOrderAndConfig({
            order: orderResponse.data,
            config: configResponse.data,
            type: 'subscribe',
          })
          navigateTo(ROUTES.PLANS.CHECKOUT)
        })
        .catch(() => {
          setCreateOrderPending(false)
        })
    }
  }

  const onApplyForAnnualPlan = () => {
    setApplyForAnnualPlanPending(true)

    requestManualPlan()
      .then(() => {
        setHasAppliedForAnnualPlan(true)

        toast.show(
          <Toast>
            <Toast.Indicator>
              <Icon name="Check" />
            </Toast.Indicator>
            <Toast.Label>Request submitted</Toast.Label>
          </Toast>,
          'long',
        )
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Apply for annual plan failed</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} elevated variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
      .finally(() => {
        setApplyForAnnualPlanPending(false)
      })
  }

  const onAddPaymentMethod = () => {
    if (selectedPlan && !createOrderPending) {
      setCreateOrderPending(true)

      Promise.all([getMerchantApiConfig(), changeMerchantApiPaymentMethod()])
        .then(([configResponse, orderResponse]) => {
          setOrderAndConfig({
            order: orderResponse.data,
            config: configResponse.data,
            type: 'change-payment-method',
          })
          navigateTo(ROUTES.PLANS.CHECKOUT)
        })
        .catch(() => {
          setCreateOrderPending(false)
        })
    }
  }

  return (
    <>
      <Layout.Main>
        <Header variant="item">
          <Header.BackButton onClick={() => goBack(ROUTES.MAIN)} aria-label="Back" />
          <Header.Title>
            {subscribedPlan ? 'Plan' : 'Subscribe to Revolut People'}
          </Header.Title>
          {subscribedPlan ? null : (
            <Header.Description>
              Subscribe to get full access to the tool
            </Header.Description>
          )}
        </Header>

        {isOnCustomPlan || !isCustomPlansFeatureEnabled ? null : (
          <VStack space="s-16" mb="s-16" align="center">
            {planType ? (
              <TabBar
                value={planType}
                onChange={value => {
                  if (value) {
                    setPlanType(value)
                  }
                }}
                variant="segmented fit"
                mx="auto"
              >
                <TabBar.Item to="monthly">Monthly</TabBar.Item>
                <TabBar.Item to="annual">Annual</TabBar.Item>
              </TabBar>
            ) : (
              <TabBarSkeleton variant="segmented" width={300}>
                <TabBarSkeleton.Item aria-selected />
                <TabBarSkeleton.Item />
              </TabBarSkeleton>
            )}

            {planType === 'monthly' ? (
              <Text variant="h6" color={Token.color.greyTone50} textAlign="center">
                <Text color={Token.color.orange}>Save up to 40%</Text> on annual
                subscription
              </Text>
            ) : null}
          </VStack>
        )}

        {selectedPlan ? (
          <Widget p="s-16">
            <VStack space="s-8">
              <Text variant="h1">
                {subscribedPlan
                  ? subscribedPlan.name
                  : selectedPlan.subscription_plan.name}
              </Text>
              <Text variant="caption" color={Token.color.greyTone50}>
                {subscribedPlan
                  ? subscribedPlan.description
                  : selectedPlan.subscription_plan.description}
              </Text>
              <VStack space="s-4" align="center" style={{ textAlign: 'center' }}>
                {isDiscountsLoading || !finalPrice ? (
                  <TextSkeleton />
                ) : (
                  <VStack align="center">
                    {planType === 'monthly' ? (
                      <>
                        <Text variant="h1">
                          {finalPrice !== planPrice ? (
                            <Text color={Token.color.greyTone50} mr="s-4">
                              <s>{planPrice}</s>
                            </Text>
                          ) : null}
                          {finalPrice}
                        </Text>
                        <Text>per active employee/month</Text>
                        <Text variant="small" color={Token.color.greyTone50}>
                          {isCurrentPlanSelected
                            ? null
                            : `${pluralize(
                                'day',
                                selectedPlan.subscription_plan.free_days,
                                true,
                              )} free`}
                        </Text>
                      </>
                    ) : null}

                    {(planType === 'monthly' && isOnMonthlyPlan) ||
                    (planType === 'annual' && isOnCustomPlan) ? (
                      <Tag
                        useIcon="Check"
                        variant="faded"
                        bg={Token.color.accent}
                        color={Token.color.white}
                        style={{
                          marginTop: Token.space.s8,
                          marginBottom: Token.space.s8,
                        }}
                      >
                        Active
                      </Tag>
                    ) : null}
                  </VStack>
                )}
              </VStack>
            </VStack>

            {hasAppliedForAnnualPlan && planType === 'annual' && !isOnCustomPlan ? (
              <Item p={0} mt="s-8">
                <Item.Avatar>
                  <Image
                    image={{
                      default: 'https://assets.revolut.com/assets/3d-images-v2/3D007.png',
                      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D007@2x.png',
                      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D007@3x.png',
                    }}
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>We’ve got your request</Item.Title>
                  <Item.Description>
                    Our team will be in touch in the next 24 hours to discuss your plan
                  </Item.Description>
                </Item.Content>
              </Item>
            ) : null}

            {info?.free_days_remaining != null &&
            info?.next_payment_date != null &&
            ((planType === 'monthly' && isOnMonthlyPlan) ||
              (planType === 'annual' && isOnCustomPlan)) ? (
              <Item p={0} mt="s-8">
                <Item.Avatar>
                  <Image
                    image={{
                      default: 'https://assets.revolut.com/assets/3d-images-v2/3D268.png',
                      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D268@2x.png',
                      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D268@3x.png',
                    }}
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Description data-testid="plan-description">
                    {info.free_days_remaining ? (
                      <>
                        You have <b>{pluralize('day', info.free_days_remaining, true)}</b>{' '}
                        of free trial period left.{' '}
                      </>
                    ) : (
                      ''
                    )}

                    {planType === 'monthly' ? (
                      <>
                        Next payment on <b>{formatDate(info.next_payment_date)}</b>.
                        <br />
                        This is an ongoing rolling plan charged monthly. You can cancel at
                        any time. For details about your plan see{' '}
                        <Link
                          href={pathToUrl(PUBLIC_ROUTES.TERMS_AND_CONDITIONS)}
                          target="_blank"
                        >
                          Terms and Conditions
                        </Link>
                      </>
                    ) : null}
                    {planType === 'annual' ? (
                      <>
                        {info.subscribed_at ? (
                          <>
                            <b>Active subscription</b> from{' '}
                            {formatDate(new Date(info.subscribed_at))} to{' '}
                            {formatDate(subDays(new Date(info.next_payment_date), 1))}
                            <br />
                          </>
                        ) : null}
                        <b>Seats purchased</b> {info.planned_headcount}
                        <br />
                        <b>Next payment on</b> {formatDate(info.next_payment_date)}
                      </>
                    ) : null}
                  </Item.Description>
                </Item.Content>
              </Item>
            ) : null}

            <Separator mt="s-16" />

            <VStack mt="s-16">
              <Subheader variant="nested">
                <Subheader.Title>What’s in the plan?</Subheader.Title>
              </Subheader>
              <Group>
                {features.map(feature => (
                  <Item
                    useIcon={feature.icon}
                    iconColor={Token.color.foreground}
                    key={feature.title}
                    p={0}
                    pb="s-24"
                  >
                    <Item.Content>
                      {feature.title ? (
                        <Item.Title>
                          <Text variant="h6">{feature.title}</Text>
                        </Item.Title>
                      ) : null}
                      <Item.Description>
                        <VStack space="s-4">
                          {feature.description ? (
                            <Text>{feature.description}</Text>
                          ) : null}
                          <VStack space="s-2">
                            {feature.items.map(item => (
                              <HStack space="s-8" align="center" key={item}>
                                <Icon name="Check" size={16} />
                                <Text>{item}</Text>
                              </HStack>
                            ))}
                          </VStack>
                        </VStack>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                ))}
              </Group>
            </VStack>
          </Widget>
        ) : (
          <SelectPlanSkeleton />
        )}
      </Layout.Main>

      {planType === 'monthly' && selectedPlan && info && !info.subscription_plan ? (
        <Layout.Actions gradient="high">
          <Footnote>
            <TermsAndConditionsDisclaimer />
          </Footnote>
          <Button onClick={onPlanSelect} elevated pending={createOrderPending}>
            Continue
          </Button>
        </Layout.Actions>
      ) : null}

      {planType === 'annual' && !hasAppliedForAnnualPlan && !isOnCustomPlan ? (
        <Layout.Actions gradient="high">
          <Footnote>
            Apply for an annual plan and our sales team will be in touch with you within
            24 hours
          </Footnote>
          <Button
            onClick={onApplyForAnnualPlan}
            elevated
            pending={applyForAnnualPlanPending}
          >
            Apply
          </Button>
        </Layout.Actions>
      ) : null}

      {isCurrentPlanSelected && noPaymentMethod ? (
        <Layout.Actions gradient="high">
          <Footnote>
            <TermsAndConditionsDisclaimer />
          </Footnote>
          <Button onClick={onAddPaymentMethod} elevated pending={createOrderPending}>
            Add payment method
          </Button>
        </Layout.Actions>
      ) : null}
    </>
  )
}

const SelectPlanSkeleton = () => {
  return (
    <Widget>
      <PlanWidgetSkeleton variant="small" />
      <Group>
        {[...Array(5)].map((_, index) => (
          <ItemSkeleton key={index} />
        ))}
      </Group>
    </Widget>
  )
}
